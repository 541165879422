import { useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import useStore from "../Store/useStore";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import QRCode from "qrcode";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";

const useAuthCheck = () => {
  const auth = getAuth();
  const database = getFirestore();
  const { setUser, setNotify, setAuthLoading, setAuthState, setUsermod, setShowLogin } = useStore();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const checkOnDB = async (currentUser) => {
    const userRef = doc(database, "users", currentUser.uid);
    try {
      const userData = await getDoc(userRef);
      const userFinalData = { ...userData.data(), id: userData?.id };
      if (userFinalData?.userId) {
        if (!userFinalData?.qrCodeGenerated) {
          const file = await QRCode.toDataURL(`https://flytant.com/${userFinalData.userId}`, {
            width: 350,
          });
          const fileRef = ref(
            getStorage(),
            `qrcode_images/${userFinalData.userId}/${userFinalData.userId}-at-${Date.now()}.png`
          );
          const upload = await uploadString(fileRef, file, "data_url", {
            cacheControl: "public,max-age=2629800",
          });
          const url = await getDownloadURL(upload.ref);
          await updateDoc(userRef, { qrCodeGenerated: true, "socialCard.qrCodeUrl": url });
          userFinalData.qrCodeGenerated = true;
          userFinalData.socialCard.qrCodeUrl = url;
        }
        setUsermod(userFinalData?.lastLoggedAs === "brand");
        if (userFinalData?.lastLoggedAs === "brand" && pathname === "/") {
          navigate("/brands");
        }
        setUser(userFinalData);
        setAuthState(false);
        setAuthLoading(false);
      } else {
        let current = {
          deviceType: "Website",
          email: currentUser?.email || "",
          creationDate: moment().unix(),
          phoneNumber: currentUser?.phoneNumber || "",
          phone: { countryCode: "", number: "" },
          freeTrials: 3,
          profileImageUrl:
            "https://firebasestorage.googleapis.com/v0/b/flytant-cb72e.appspot.com/o/default_user_image%2FGroup%201763.png?alt=media&token=ef261d0b-a8d9-4791-a0c5-2d8338b77ea3",
          shouldShowInfluencer: false,
          userId: currentUser.uid,
          socialCardEnabled: true,
          lastLoggedAs: "influencer",
          socialCard: {
            brandImageUrl:
              "https://firebasestorage.googleapis.com/v0/b/flytant-cb72e.appspot.com/o/flytant_image%2Fappicon.png?alt=media&token=f8f276ff-8186-4d51-87fb-b608dcc532d6",
            featureImageUrl:
              "https://firebasestorage.googleapis.com/v0/b/flytant-cb72e.appspot.com/o/socail_card_default_feature_graphic%2Fsocialprofilebg.png?alt=media&token=fb74e1c7-f50b-462d-bb33-903377eb966e",
            profileImageUrl:
              "https://firebasestorage.googleapis.com/v0/b/flytant-cb72e.appspot.com/o/default_user_image%2FGroup%201763.png?alt=media&token=ef261d0b-a8d9-4791-a0c5-2d8338b77ea3",
            bio: "",
            manualLocation: "",
            name: "",
          },
        };
        setShowLogin((prev) => (prev ? prev : !prev));
        setUser({ required: true, tempData: current });
        setAuthState(false);
        setAuthLoading(false);
      }
    } catch (err) {
      setAuthState(false);
      setAuthLoading(false);
      setNotify({ status: false, message: err?.message });
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (current) => {
      if (current && !current.isAnonymous) {
        setAuthLoading(true);
        checkOnDB(current);
      } else {
        setUser({});
        setAuthState(false);
        setAuthLoading(false);
      }
    });
  }, []);
};

export default useAuthCheck;
